import React from 'react'
import { TranslatedErrorBoundary } from '../../components'
import { ThemeProvider as LegacyThemeProvider } from '@creditas/stylitas'
import { ThemeProvider, standard } from '@creditas-ui/react'
import tokens from '@creditas/tokens'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from '../../services/bff/apollo-client'

import { Confirmation } from './Confirmation'
import { useGetConfirmation } from '../../services/bff/queries/getConfirmation'
import { ConfirmationCustomProps } from './index.spa'
import { setUserContext } from '../../utils'

class App extends React.Component<ConfirmationCustomProps> {
  componentDidCatch() {}

  componentDidMount() {
    const { clientId } = this.props
    setUserContext({ id: clientId.toString() })
  }

  render() {
    const { authToken, clientId, onLoad } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <LegacyThemeProvider theme={{ ...tokens }}>
            <ThemeProvider theme={standard}>
              <Confirmation
                request={useGetConfirmation}
                clientId={clientId}
                onLoad={onLoad}
              />
            </ThemeProvider>
          </LegacyThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
