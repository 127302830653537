import { ChartStates } from 'modules/chart/enums'

export interface Summary {
  salaryAdvanceSummary: SalaryAdvanceSummary
}

interface SalaryAdvanceSummary {
  amountAvailableToAdvanceToday: number
  totalAmountAdvanceableInCurrentCycle: number
  amountAdvancedInCurrentCycle: number
  currentCycleEndDate: string
  fee: number
  date: Date
}

export interface Confirmation {
  salaryAdvanceConfirmation: SalaryAdvanceConfirmation
}

interface SalaryAdvanceConfirmation {
  amountAvailableToAdvanceToday: number
  totalAmountAdvanceableInCurrentCycle: number
  amountAdvancedInCurrentCycle: number
  fee: number
  amountToBeDiscounted: number
  amount: number
  currentCycleEndDate: string
  salaryAdvanceId: string
  maxAmountAdvanceableDaily: number
  banker: Banker
  signatureKey: string
}

export interface ChartStatus {
  salaryAdvanceChartStatus: SalaryAdvanceChartStatus
}

interface SalaryAdvanceChartStatus {
  totalAmountAdvanceableInCurrentCycle: number
  amountAvailableToAdvanceToday: number
  amountAdvancedInCurrentCycle: number
  status: ChartStates
  maxAmountAdvanceableDaily: number
}

export enum Banker {
  Scd = 'SCD',
  Socinal = 'SOCINAL',
}

export interface Policy {
  fee: string | number
  feeThreshold: string | string
  freeAfterThreshold: number
  freeSalaryAdvancesPerUser: number
  currentCycleStartDate: string
}

export interface SalaryAdvancePolicy {
  policy: Policy
}
